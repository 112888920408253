import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import theme from '@/theme';
import FacebookIcon from '@material-ui/icons/Facebook';
import PinterestIcon from '@material-ui/icons/Pinterest';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  socialLink: {
    margin: '.5rem 0 0 .5rem',
    borderRadius: '100%',
    height: 30,
    width: 40,
    display: 'inline-block',
    "& svg": {
      color: 'inherit',
    },
    "&:hover svg": {
      color: 'white',
    },
    "& span": {
      color: theme.palette.grey[100],
    },
  }
});

export default function SocialAccounts(props) {
  const classes = useStyles();
  return (
    <div>
        <Grid container direction="row" justifyContent="center">
          <Grid item>
            <Link target="_blank" className={classes.socialLink} href="https://www.youtube.com/user/baywoodgreensgolf" rel="noopener">
              <YouTubeIcon />
              <Typography variant="srOnly">Connect Baywood Greens on YouTube</Typography>
            </Link>

            <Link target="_blank" className={classes.socialLink} href="https://www.facebook.com/baywoodgreenscommunity" rel="noopener">
              <Typography variant="srOnly" >Follow Baywood Greens on Facebook</Typography>
              <FacebookIcon />
            </Link>

            <Link target="_blank" className={classes.socialLink} href="https://www.pinterest.com/baywoodgreensde/_created/" rel="noopener">
              <PinterestIcon />
              <Typography variant="srOnly" >Follow Baywood Greens on Pinterest</Typography>
            </Link>

            <Link target="_blank" className={classes.socialLink} href="https://www.instagram.com/baywoodgreens/" rel="noopener">
              <InstagramIcon />
              <Typography variant="srOnly" >Connect with Baywood Greens on Instagram</Typography>
            </Link>
          </Grid>
        </Grid>
    </div>
  );
}
